import React, { Component } from 'react';
import validator from 'validator';
import FormValidator from '../validations';
import {
  Redirect,Link
} from 'react-router-dom';
import axios from 'axios';
import SocialButton from './SocialButton'
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'
import Select from 'react-select';

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
// import { connect } from 'react-redux';
// import { userActions } from '../../_actions';
// import { userService } from '../../_services';



const apiUrl = process.env.REACT_APP_API

var querystring = require('querystring');



class Registration extends Component {
  constructor(props) {
    super(props);
    const type=props.type;
    this.state = {
        user:{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          repassword:'',
          country:'',
          phoneNumber:'',
        },
        selectedOption:'',
        options:{},
        changePassword:true,
        type: type,
        messageFromServer: '',
        messageColor:'success',
        visible: false,
        redirect:false,
        redirectUrl:'',
        collapse: true,
        fadeIn: true,
        timeout: 300,
        errors: [],
        errorClasses: [],
        countries: [],
        disabled:false,
        nextStep:false,
        emailExist:'',
        socialLoginCheck:false,
        socialInfo:{
          email:'',
          firstName:'',
          lastName:'',
          token:'',
          type:''
        },
        verifyEmail:false,
        checked: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.countryOptions = this.countryOptions.bind(this);
    this.logChange = this.logChange.bind(this);
    this.handleNextView = this.handleNextView.bind(this);
    this.handlePreviousView = this.handlePreviousView.bind(this);
    this.agreeRegPolicy = this.agreeRegPolicy.bind(this);
    this.handleEmailCheck = this.handleEmailCheck.bind(this);

    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
  }
  _isAgreed = false;
  // _isNextStep = false;
  onDismiss() {
    this.setState({ visible: false });
  }
  handleValidation(){
    const { t } = this.props;
    let fields = this.state.user;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: 'firstName',
        method: validator.isEmpty,
        validWhen: false,
        message: t('registorPage.formInfo.firstName.errorMessage')
      },
      {
        field: 'lastName',
        method: validator.isEmpty,
        validWhen: false,
        message: t('registorPage.formInfo.lastName.errorMessage')
      },
      {
        field: 'email',
        method: validator.isEmpty,
        validWhen: false,
        message: t('registorPage.formInfo.email.errorMessage')
      },
      {
        field: 'email',
        method: validator.isEmail,
        validWhen: true,
        message: t('registorPage.formInfo.email.errorMessageEmail')
      },
      {
        field: 'password',
        method: validator.isEmpty,
        validWhen: false,
        message: t('registorPage.formInfo.password.errorMessage')
      },
      {
        field: 'password',
        method: validator.isLength,
        args:[{
          min:8
        }],
        validWhen: true,
        message: t('registorPage.formInfo.password.errorMessagePassword')
      },
      {
        field: 'repassword',
        method: validator.isEmpty,
        validWhen: false,
        message:  t('registorPage.formInfo.rePassword.errorMessage')
      },
      {
        field: 'repassword',
        method: validator.equals,
        validWhen: true,
        args: {comparison:fields.password},
        message: t('registorPage.formInfo.rePassword.errorMessagePassword')
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if(!validation.isValid){
        formIsValid = false;
        for (var variable in validation) {
          if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
            if(validation[variable]['isInvalid']){
              errors[variable] = validation[variable]['message'];
              errorClasses[variable] = "is-invalid";
            }
          }
        }

    }
    this.setState({errors: errors,errorClasses:errorClasses});
    return formIsValid;
   }
   handleEmailCheck(e){
     let emailExist = e.target.value;
     this.setState({
       emailExist
     })
   }
  handleChange(e){
    const name =  e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
        user
    });

    // this.handleValidation();
  }
  componentDidMount() {
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    axios.get( apiUrl + '/countries', {
      headers: { lang: languageCode }
    }).then(res => {
      const countries = res.data;
      this.setState({ countries });
    })

    if(localStorage.getItem('user')) {
      let language = JSON.parse(localStorage.getItem('lang'))
      let languageCode = language.code
      let currentComponent=this;
      currentComponent.setState({
          redirect:true,
          redirectUrl:`/${languageCode}/`
      });
    }
  }
  UNSAFE_componentWillMount () {
    this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
}
  componentWillUnmount() {
    this._isAgreed = false;
    this._isNextStep = false;
  }
  registerUser(e) {
    let currentComponent = this;
    if(this.state.socialLoginCheck === true ){
      let requestUrl = '/users/social-login';
      let postdata = {
          firstName: this.state.socialInfo.firstName,
          lastName:  this.state.socialInfo.lastName,
          email:  this.state.socialInfo.email,
          token:  this.state.socialInfo.token,
          type: this.state.socialInfo.type
        };
        axios.post(apiUrl+requestUrl,querystring.stringify(postdata),
           {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  'lang':this.props.match.params.lang
              }
            }).then(function(response) {
                currentComponent.setState({
                    messageFromServer: response.data.message
                });
                if(response.data.code!==200){
                  currentComponent.setState({messageColor:'danger'});
                  if(response.data.errors){
                      const res = response.data.errors
                      const errors = currentComponent.state.errors;
                      for (var index of Object.keys(res)) {
                        errors[index] = res[index];
                      }
                    currentComponent.setState({
                        errors
                    });
                  }
                }
                else {
                  var data = response.data.success.user
                  localStorage.setItem('user', JSON.stringify(data));
                  let language = JSON.parse(localStorage.getItem('lang'))
                  let languageCode = language.code
                  window.location = `/${languageCode}/`
                }
        });
    } else {
      if(this.handleValidation()){
        let requestUrl = '/users/register';


        let postdata = {
            firstName: this.state.user.firstName,
            lastName:  this.state.user.lastName,
            email:  this.state.user.email,
            password:  this.state.user.password,
          };
          if(this.state.user.country){
            postdata.country=this.state.user.country
          }
          if(this.state.user.phoneNumber){
            postdata.phoneNumber=this.state.user.phoneNumber
          }
          axios.post(apiUrl+requestUrl,querystring.stringify(postdata),
             {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    'lang':this.props.match.params.lang
                }
              }).then(function(response) {
                  currentComponent.setState({
                      messageFromServer: response.data.message
                  });
                  if(response.data.code!==200){
                    currentComponent.setState({messageColor:'danger'});
                    if(response.data.errors){
                        const res = response.data.errors
                        const errors = currentComponent.state.errors;
                        for (var index of Object.keys(res)) {
                          errors[index] = res[index];
                        }
                      currentComponent.setState({
                          errors
                      });
                    }
                  }
                  else {
                    currentComponent.setState({
                      verifyEmail:true,
                    })
                  }
          });
      }
    }
  }

  countryOptions() {
    var countryList = this.state.countries;
    var option = {}
    var sayHello = Object.keys(countryList).map(function (i) {
       option = {
        value:countryList[i].id,
        label:countryList[i].name
      }
      return option;
    });
    return sayHello
  }
  logChange(val) {
    var selectedOptionValue = val.value
    const user = this.state.user

    var country = 'country'
    user[country] = selectedOptionValue;
    this.setState({
        user,
    });
    // this.handleValidation();

  }

  handleNextView() {
    if(this.handleValidation()) {
      const { t } = this.props;

      axios.post( apiUrl + '/users/validate-email?email='+this.state.emailExist,'',{headers:{'lang':this.props.match.params.lang}}).then(res => {
        const info = res.data;
        // console.log(info)
        if(info.code === 200 && info.success.valid === false ) {
          let errors = {};
          let errorClasses = {};
          errors['email'] = t('registorPage.formInfo.email.emailExistMessage');
          errorClasses['email'] = "is-invalid";

          this.setState({errors: errors,errorClasses:errorClasses});
        }else {
          this.setState({
            nextStep: true
          });
        }
      })
    }
  }


  handlePreviousView() {
    this.setState({
      nextStep: false,
      disabled: false

    });
  }

  agreeRegPolicy(e) {
    this.setState({
      disabled: !this.state.checked,
      checked:!this.state.checked
    });
     
  }



  handleSocialLogin = (user) => {
    var tokenType = '';
    if(user._provider === 'google'){
      tokenType = user._token.idToken
    }else {
      tokenType = user._token.accessToken
    }
    this.setState({
      socialInfo:{
        email:user._profile.email,
        firstName:user._profile.firstName,
        lastName:user._profile.lastName,
        token:tokenType,
        type:user._provider
      },
      socialLoginCheck:true,
      nextStep: true
    });
    //console.log(user)
    //console.log(this.state.socialInfo)
  }

  handleSocialLoginFailure = (err) => {
    console.error(err)
  }

  render() {
    if(this.state.redirect){
      return (<Redirect to={this.state.redirectUrl} />);
    }
    // const REACT_VERSION = React.version;
    
    // console.log({REACT_VERSION})
    let countryData = this.countryOptions()
    const { t } = this.props;

    return (
      <div>
        <MetaTags/>
        {
          this.state.verifyEmail ?
        //  true ?
          <div className="sign-up-container">
            <div className="table">
              <div className="cell">
                <div className="section-title no-margin">{t('registorPage.thankyouTitle')}</div>
                <div className="section-description">{t('registorPage.thankyouDescription')}</div>
              </div>
            </div>
          </div>
          :
          this.state.nextStep ?
            //  true ?
            <div className="agree-policy-box">
              <div className="wrapper">
              <h1 className="title">{t('legal.pageTitle')}</h1>
              <div className="content-box" dangerouslySetInnerHTML={{__html: t('legal.pageContent')}}>
              
            
              </div>
                <div className="bottom">
                  <div className="row clear">
                    <div className="left">
                      <input id={this.id} checked={this.state.checked}  onChange={this.agreeRegPolicy} type="checkbox" className="css-checkbox" /><label onClick={this.agreeRegPolicy} className="css-label">{t('registorPage.agreeRegPolicyButtonText')}</label>
                    </div>
                    <div className="right">
                      <Link className="pdf-link" to="/user_agreement.pdf" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path className="cls-1" d="M96,0A32.09,32.09,0,0,0,64,32V480a32.09,32.09,0,0,0,32,32H416a32.09,32.09,0,0,0,32-32V128L320,0Z"/>
                              <path className="cls-2" d="M352,128h96L320,0V96A32.09,32.09,0,0,0,352,128Z"/>
                              <polygon className="cls-3" points="448 224 352 128 448 128 448 224"/>
                              <path className="cls-4" d="M384,416a16,16,0,0,1-16,16H16A16,16,0,0,1,0,416V256a16,16,0,0,1,16-16H368a16,16,0,0,1,16,16Z"/>
                              <path className="cls-5" d="M69.74,303.15a8.81,8.81,0,0,1,8.69-8.83H108c16.64,0,31.62,11.14,31.62,32.48,0,20.22-15,31.49-31.62,31.49H86.62v16.89c0,5.64-3.58,8.82-8.19,8.82a8.62,8.62,0,0,1-8.69-8.82Zm16.88,7.28V342.3H108c8.58,0,15.36-7.56,15.36-15.5,0-8.94-6.78-16.37-15.36-16.37Z"/>
                              <path className="cls-5" d="M164.66,384c-4.23,0-8.84-2.3-8.84-7.92V303.41c0-4.59,4.61-7.94,8.84-7.94H194c58.47,0,57.19,88.53,1.15,88.53Zm8.06-72.91V368.4H194c34.55,0,36.08-57.31,0-57.31Z"/>
                              <path className="cls-5" d="M271.87,312.11v20.34H304.5c4.6,0,9.21,4.61,9.21,9.07,0,4.22-4.61,7.68-9.21,7.68H271.87v26.86a7.58,7.58,0,0,1-7.66,7.92c-5.63,0-9.07-3.44-9.07-7.92V303.39c0-4.59,3.45-7.93,9.07-7.93h44.91c5.63,0,9,3.34,9,7.93a8.84,8.84,0,0,1-9,8.71H271.87Z"/>
                              <path className="cls-3" d="M368,432H64v16H368a16,16,0,0,0,16-16V416A16,16,0,0,1,368,432Z"/>
                            </g>
                          </g>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <button disabled={!this.state.disabled} onClick={this.registerUser}>{t('registorPage.submitButtonText')}</button>
                  <button onClick={this.handlePreviousView}>{t('registorPage.backButtonText')}</button>
                </div>
              </div>
            </div>
          :
            <div className="sign-up-container">
                <div className="table">
                  <div className="cell">
                    <h1>{t('registorPage.pageTitle')}</h1>
                    <div className="row">
                      <input tabIndex='1' type="text" placeholder={t('registorPage.formInfo.firstName.placeholder')} name="firstName" className={this.state.errorClasses.firstName} value={this.state.user.firstName} onChange={this.handleChange} />
                      <div className="invalid-feedback">{this.state.errors.firstName}</div>
                    </div>
                    <div className="row">
                      <input tabIndex='2' type="text" placeholder={t('registorPage.formInfo.lastName.placeholder')} name="lastName" value={this.state.user.lastName} className={this.state.errorClasses.lastName} onChange={this.handleChange} />
                      <div className="invalid-feedback">{this.state.errors.lastName}</div>
                    </div>
                    <div className="row">
                      <input tabIndex='3' type="email" onBlur={this.handleEmailCheck} placeholder={t('registorPage.formInfo.email.placeholder')} name="email" value={this.state.user.email} className={this.state.errorClasses.email}  onChange={this.handleChange} />
                      <div className="invalid-feedback">{this.state.errors.email}</div>
                    </div>
                    <div className="row">
                      <input tabIndex='4' type="password" placeholder={t('registorPage.formInfo.password.placeholder')} name="password" value={this.state.user.password} className={this.state.errorClasses.password} onChange={this.handleChange} />
                      <div className="invalid-feedback">{this.state.errors.password}</div>
                    </div>
                    <div className="row">
                      <input tabIndex='5' type="password" placeholder={t('registorPage.formInfo.rePassword.placeholder')} name="repassword" value={this.state.user.repassword} className={this.state.errorClasses.repassword} onChange={this.handleChange} />
                      <div className="invalid-feedback">{this.state.errors.repassword}</div>
                    </div>
                    <div className="row country-row">
                      <Select
                        classNamePrefix="react-select"
                        isMulti={false}
                        isSearchable ={false}
                        value={[countryData.find(opt =>opt.value === this.state.user.country)]}
                        name="country"
                        options={countryData}
                        onChange={this.logChange}
                        className={this.state.errorClasses.country + ' react-select'}
                        placeholder ={t('registorPage.formInfo.country.placeholder')}
                        tabIndex='6'
                      />
                      <div className="invalid-feedback">{this.state.errors.country}</div>
                    </div>
                    <div className="row">
                      <input tabIndex='7' placeholder={t('registorPage.formInfo.phoneNumber.placeholder')} value={this.state.user.phoneNumber} type="tel" onChange={this.handleChange} name="phoneNumber"/>
                    </div>
                    <div className="bottom row">
                      <button tabIndex='8' onClick={this.handleNextView}>{t('registorPage.nextButtonText')}</button>
                    </div>
                    <div className="social-login-box">
                      <div className="title">{t('registorPage.socialLoginText')}</div>
                        <SocialButton
                          provider='facebook'
                          appId='516580402200598'
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                        <svg  viewBox="0 0 110 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Login-Copy" transform="translate(-38.000000, -540.000000)">
                                    <g id="Group" transform="translate(38.000000, 540.000000)">
                                        <rect id="Rectangle-9-Copy" fill="#3B5998" fillRule="nonzero" x="0" y="0" width="110" height="40" rx="4"></rect>
                                        <path d="M59.3461538,11.1298077 L59.3461538,13.9855769 L57.6478365,13.9855769 C56.3173077,13.9855769 56.0685096,14.6237981 56.0685096,15.5432692 L56.0685096,17.5877404 L59.2379808,17.5877404 L58.8161058,20.7896635 L56.0685096,20.7896635 L56.0685096,29 L52.7584135,29 L52.7584135,20.7896635 L50,20.7896635 L50,17.5877404 L52.7584135,17.5877404 L52.7584135,15.2295673 C52.7584135,12.4927885 54.4350962,11 56.8798077,11 C58.0480769,11 59.0540865,11.0865385 59.3461538,11.1298077 Z" id="facebook" fill="#FFFFFF"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        </SocialButton>
                        <SocialButton
                          provider='google'
                          appId='375040050301-kg3oclpbdpkpgppgaekdn0ttauvv9p5f.apps.googleusercontent.com'
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                        <svg viewBox="0 0 110 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Login-Copy" transform="translate(-167.000000, -540.000000)" fillRule="nonzero">
                                    <g id="Group-2" transform="translate(167.000000, 540.000000)">
                                        <rect id="Rectangle-9-Copy-2" fill="#f4f4f4" x="0" y="0" width="110" height="40" rx="4"></rect>
                                        <g id="google" transform="translate(46.000000, 11.000000)">
                                            <path d="M3.98917969,10.877625 L3.362625,13.2166406 L1.07258203,13.2650859 C0.388195313,11.9956992 0,10.5433594 0,9 C0,7.50758203 0.362953125,6.10020703 1.0063125,4.86098438 L1.00680469,4.86098438 L3.04558594,5.23476563 L3.93869531,7.2613125 C3.75176953,7.80626953 3.64988672,8.39126953 3.64988672,9 C3.64995703,9.66065625 3.76962891,10.2936445 3.98917969,10.877625 Z" id="Shape" fill="#FBBB00"></path>
                                            <path d="M17.8427461,7.3186875 C17.9461055,7.86311719 18,8.42537109 18,9 C18,9.64434375 17.9322539,10.2728672 17.8031953,10.8791367 C17.3650781,12.9422109 16.2202852,14.7436875 14.6344219,16.0185234 L14.6339297,16.0180312 L12.0659766,15.8870039 L11.7025312,13.6181953 C12.7548281,13.0010625 13.5772031,12.0352852 14.0103984,10.8791367 L9.19785937,10.8791367 L9.19785937,7.3186875 L14.0806055,7.3186875 L17.8427461,7.3186875 Z" id="Shape" fill="#518EF8"></path>
                                            <path d="M14.6338945,16.0180312 L14.6343867,16.0185234 C13.0920469,17.2582383 11.1327891,18 9,18 C5.57258203,18 2.59270312,16.0843008 1.07258203,13.2651211 L3.98917969,10.8776602 C4.74922266,12.9061055 6.70598437,14.3500781 9,14.3500781 C9.98602734,14.3500781 10.909793,14.0835234 11.7024609,13.6181953 L14.6338945,16.0180312 Z" id="Shape" fill="#28B446"></path>
                                            <path d="M14.7446719,2.07196875 L11.8290586,4.4589375 C11.0086875,3.94614844 10.0389375,3.64992187 9,3.64992187 C6.65405859,3.64992187 4.66069922,5.16012891 3.93873047,7.2613125 L1.00680469,4.86098437 L1.0063125,4.86098437 C2.50417969,1.97307422 5.52164062,0 9,0 C11.1837305,0 13.1859844,0.777867188 14.7446719,2.07196875 Z" id="Shape" fill="#F14336"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </SocialButton>

                    </div>

                  </div>
                </div>
            </div>

        }
        <Footer/>
      </div>

    );
  }
}
// function mapStateToProps(state) {
//   const { users, authentication } = state;
//   const { user } = authentication;
//   return {
//       user,
//       users
//   };
// }

//const Registration = connect(mapStateToProps)(RegistrationPage);

Registration.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Registration);
