import React, { Component } from 'react';
import Footer from '../../footerComponent/footer'
import validator from 'validator';
import FormValidator from '../../validations';
import axios from 'axios'
import { authHeader } from '../../../_helpers';
import MetaTags from '../../metaTags'
import PropTypes from "prop-types";
import ReactModal from 'react-modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
// var moment = require('moment');

 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

var querystring = require('querystring');

const apiUrl = process.env.REACT_APP_API
class ExampleCustomInput extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
  };

  render() {
    return (
      <div className={this.props.errorClass !== undefined ? this.props.errorClass + ' datepicker-btn' : 'datepicker-btn'} onClick={this.props.onClick}>
        {this.props.value}
      </div>
    );
  }
}


ExampleCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class WithDraw extends Component {
  constructor(props) {
    super(props);
    //const type=props.type;
    this.state = {
      userFields:{},
      countries:{},
      errors: [],
      errorClasses: [],
      user:{
        // name:'',
        // country:'',
        // phoneNumber:'',
        // account:''
      },
      amountFieldErrorClass:'',
      withDrawAmount:0,
      selectedOption:'',
      additionalFields:[],
      userBalance:{},
      feePercent:0,
      transferFee:0,
      feePercentAmount:0,
      transferFeeAmount:0,
      totalWithdrawAmount:0,
      thankYou:false,
      messageFromServer:'',
      messageForCountry:'',
      showModal:false
    }

    this.countryOptions = this.countryOptions.bind(this);
    this.logChange = this.logChange.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleWithDraw = this.handleWithDraw.bind(this);
    this.handleWithDrawAmount = this.handleWithDrawAmount.bind(this);

    this.handleCalculation = this.handleCalculation.bind(this);
    this.dateChange = this.dateChange.bind(this);


    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);




  }
  _isMounted= false;
  _isLoaded = false;
  _isUserBalanceLoaded = false;

  handleOpenModal (e) {
    this.setState({ showModal: true});
  }

  handleCloseModal () {
    localStorage.setItem('userState', JSON.stringify({'fillArmenian' : true}))
    this.setState({ showModal: false});
  }

  componentDidMount(){
    var currentComponent = this;
    var req = axios.get( apiUrl + '/userfields/countryfields',{headers:authHeader()}).then(res => {
      const countries = res.data.success.fields;
      const userSelectedCountry = res.data.success.countryId
      currentComponent._isLoaded = true;
      currentComponent.setState({ countries,userSelectedCountry });

      if(userSelectedCountry !== '' && userSelectedCountry !== undefined) {

        currentComponent.logChange(userSelectedCountry);
      }else {
        if(localStorage.getItem('userState')) {
          let userState = JSON.parse(localStorage.getItem('userState'));

          if(userState.fillArmenian === true) {
            localStorage.setItem('userState' , JSON.stringify({"fillArmenian" : false}))
          }
        }
      }
    })
    req.then(x =>
      axios.get( apiUrl + '/userfields/user',{headers:authHeader()}).then(res => {
        const fields = res.data;
        currentComponent._isMounted = true
        const user = currentComponent.state.user;
        const fieldName = fields.success.fields
        fieldName.map(function (i) {
          if(i.value !== '' && i.value !== undefined) {
            if(i.field === 'phoneNumber') {
              let str = i.value;
              user[i.field] = `+${str.replace(/\D/g,'')}`
            }else {
              user[i.field] = i.value
            }

          } else {
            user[i.field] = ''
          }
          return user;
        });
        currentComponent.setState({ userFields:fields,user},()=>{
          if(user['country'] !== '' && user['country'] !== undefined) {
            currentComponent.logChange(user['country']);
          }
        });
      })

    )

    axios.get(apiUrl+'/userbalance',{headers:authHeader()})
        .then(function(response) {
            const userBalance = response.data.success.balance;
            //console.log(userBalance)
            currentComponent._isUserBalanceLoaded = true;
              currentComponent.setState({ userBalance });
        });
  }


  componentWillUnmount() {
    this._isMounted= false;
    this._isLoaded= false;
    this._isUserBalanceLoaded= false;
  }

  handleChange(e){
    const name =  e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
        user
    });

    // this.handleValidation();
  }

  setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
      });
    });
  }

  handleWithDraw(e){
    const val =  e.target.value;
    this.setInputFilter(document.getElementById("yourinput"), function(val) {
      return /^-?\d*[.,]?\d{0,2}$/.test(val) && (val === "" || parseInt(val,0) <= 999999)
    });
    this.setState({
      withDrawAmount:val,
    },()=>{
      this.handleCalculation();
    });

  }

  handleCalculation(){
    const val =  this.state.withDrawAmount;


    var feePercent = this.state.feePercent
    var transferFee = this.state.transferFee
    //
    // console.log(transferFee)
    // console.log(feePercent)

    //var feePercentAmount = this.state.feePercentAmount
    //var transferFeeAmount = this.state.transferFeeAmount

    var totalWithdrawAmount = this.state.totalWithdrawAmount

    var newFeePercent = (val/100)*feePercent;
    // Math.floor(1.07999999999999* 100) / 100
    newFeePercent =  Math.floor(newFeePercent * 100) / 100
    //newFeePercent =  parseInt((newFeePercent * 100) / 100 , 0)
    //newFeePercent = parseInt((newFeePercent * 100) / 100 , 0)


    totalWithdrawAmount =  val - newFeePercent - transferFee
    totalWithdrawAmount = Math.floor(totalWithdrawAmount * 100) / 100
    //totalWithdrawAmount = parseInt((totalWithdrawAmount * 100) / 100 , 0)
    this.setState({
      feePercentAmount:newFeePercent,
      transferFeeAmount:transferFee,
      totalWithdrawAmount
    });
    // this.handleValidation();
  }

  handleWithDrawAmount(){
    const val =  this.state.withDrawAmount;
    const minamal = this.state.userBalance.minimumAmount
    if(val < minamal || val === undefined || val === '') {
      this.setState({
        amountFieldErrorClass:'is-invalid',
      })
      return false
    }else {
      this.setState({
        amountFieldErrorClass:'',
      });
      return true
    }
  }

  handleValidation(){
    //let fields = this.state.user;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    var validationRules = []
    const { t } = this.props;


    var field = this.state.userFields.success.fields
    var additionalField = this.state.additionalFields
    //console.log(additionalField)

    Object.keys(field).map(function (i) {
      if(field[i].isRequred === true) {
        var validationRule = {
          field: field[i].field,
          method: validator.isEmpty,
          validWhen: false,
          message: t('earningsPage.fieldErrorMessage')
       }

       validationRules.push(validationRule);
      }

      if(field[i].type === "tel") {
        validationRule = {
          field: field[i].field,
          method: validator.isNumeric,
          validWhen: true,
          message: 'Մուտքագռեք միայն թվեր'
       }

       validationRules.push(validationRule);
      }
      return validationRule

    });

    Object.keys(additionalField).map(function (i) {
      if(additionalField[i].isRequred === true) {
        var validationRule = {
          field: additionalField[i].field,
          method: validator.isEmpty,
          validWhen: false,
          message: t('earningsPage.fieldErrorMessage')
       }
       validationRules.push(validationRule);
      }
      if(additionalField[i].type === "tel") {
        validationRule = {
          field: additionalField[i].field,
          method: validator.isNumeric,
          options :[{
            no_symbols: false
          }],
          validWhen: true,
          message: 'Մուտքագռեք միայն թվեր'
       }

       validationRules.push(validationRule);
      }
      return validationRule

    });

    // console.log(validationRules)

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if(!validation.isValid){
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
          if(validation[variable]['isInvalid']){
            errors[variable] = validation[variable]['message'];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({errors: errors,errorClasses:errorClasses});
    return formIsValid;
   }

   withdraw(){
     if( this.handleValidation() && this.handleWithDrawAmount() ) {
       let requestUrl = '/payouts';
       const data ={};
       var currentComponent = this
       const {user,withDrawAmount} = this.state;
        data.user = JSON.stringify(user);
        data.amount = withDrawAmount;

        axios.post(apiUrl+requestUrl,querystring.stringify(data),
           {
              headers:authHeader()
            }).then(function(response) {

                if(response.data.code!==200){
                  currentComponent.setState({messageColor:'danger'});
                  if(response.data.error){
                    currentComponent.setState({
                        messageFromServer: response.data.error.message
                    });
                  }
                }
                else {
                    // let url = '/user-dashbord/earnings/history';
                    // window.location = url

                    currentComponent.setState({
                      thankYou:true
                    })
                }
        });
     }
   }

  countryOptions() {
    var countryList = this.state.countries;
    var option = {}
    var sayHello = Object.keys(countryList).map(function (i) {
       option = {
        value:countryList[i].id,
        label:countryList[i].name
      }
      return option;
    });
    return sayHello

  }
  
  logChange(val) {
    // console.log(val)
    // var selectedOptionName = val.label
    const { t } = this.props;

    var selectedOptionValue = ''
    var messageForCountry = ''
    var showModal;
    let user = this.state.user


    
    if(val.value !== undefined) {
       selectedOptionValue = val.value
    }else {
       selectedOptionValue = val

    }
   
    let userState = JSON.parse(localStorage.getItem('userState'));
    
    if(selectedOptionValue === "5cc6c64254fbb78aa54372ce") {
      messageForCountry = t('earningsPage.messageForCountry')
      localStorage.setItem('userState' , JSON.stringify({"fillArmenian" : true}))
      if(userState && userState.fillArmenian === true) {
        showModal = false
      }else {
        showModal = true
      }
    }else {
      messageForCountry = ""
      localStorage.setItem('userState' , JSON.stringify({"fillArmenian" : false}))
    }
     user = {}
    const countryList = this.state.countries
    var country = 'country'
    user[country] = selectedOptionValue;

    const additionalFields = countryList.find(x => x.id === selectedOptionValue).fields;
    var feePercent=countryList.find(x => x.id === selectedOptionValue).fee
    var transferFee=countryList.find(x => x.id === selectedOptionValue).transferFee
    additionalFields.map(function (i) {
      if(i.value !== '') {
        if(i.field === 'phoneNumber') {
          let str = i.value;
          user[i.field] = `+${str.replace(/\D/g,'')}`
        }else {
          user[i.field] = i.value
        }
      } else {
        user[i.field] = ''
      }
      return user;
    });

    this.setState({
        user,
        // selectedOption:selectedOptionName,
        messageForCountry,
        additionalFields,
        feePercent,
        transferFee,
        showModal,
        amountFieldErrorClass:'',
        errors:'',
        errorClasses:'',
        messageFromServer:''
    },()=>{
      this.handleCalculation();
    });


  }

  dropdownChange(val , name) {
    const user = this.state.user
    let selectedOptionValue = val.value
    let fieldName = name.name
    // console.log(selectedOptionValue ,fieldName)
    user[fieldName] = selectedOptionValue;
    this.setState({
      user,
    })
  }
  dateChange(name,date) {
    const user = this.state.user
    user[name] = date
    this.setState({
      user
    })
  }
  render() {
    if(this._isMounted === false || this._isLoaded === false || this._isUserBalanceLoaded === false) {
      return (
        <div className="loader-box">
          <span className="loader"><span className="loader-inner"></span></span>
        </div>
      )
    }else {
      var defaultFields = this.state.userFields.success.fields
      var additionalFields = this.state.additionalFields
      var userBalance = this.state.userBalance
    }
    let language = JSON.parse(localStorage.getItem('lang'))
               let languageCode = language.code
    // console.log(defaultFields)
    // console.log(this.state)
    if(this.state.thankYou === true) {
      window.setTimeout(function(){
        let url = '/'+languageCode+'/user-dashbord/earnings/history';
        window.location = url
      }, 3000);
    }
    var className = ''
    if(defaultFields.length > 3) {
      className = 'withdraw-info-box-container no-padding-bottom'
    }else {
      className="withdraw-info-box-container "
    }
    if(additionalFields.length > 0 ){
      className = 'withdraw-info-box-container no-padding-bottom'
    }
    // console.log(this.state.user)
    let countryData = this.countryOptions()
    const { t } = this.props;
    return (
      <div>
        <ReactModal
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           ariaHideApp={false}
           shouldCloseOnOverlayClick={false}
           portalClassName="ReactModalPortal prompt"
        >
            <div className="title">{this.state.messageForCountry}</div>
            <button onClick={this.handleCloseModal}>{t('earningsPage.submitButtonText')}</button>
      
        </ReactModal>
        <div className={this.state.thankYou ? "withdraw-info-container table" : "withdraw-info-container"}>
          <MetaTags/>
          {this.state.thankYou ?
            <div className="cell">
              <div className="section-title">
                {t('earningsPage.thankyouTitle')}
              </div>
              <div className="section-description"> {t('earningsPage.thankyouDescription')}</div>
            </div>
            :
            <div className="wrapper">
              <div className="section-title">
               {t('earningsPage.withDrawPageTitle')}
              </div>
              <div className="section-description">{t('earningsPage.withDrawPageDescription')}</div>
              <div className={className}>
                <div className="left-section">
                  <div className="form-box">
                    <div className="row">
                      <div className="text">{t('earningsPage.paymentMetodeTitle')}</div>
                      <div className="title">{t('earningsPage.paymentMetodeOption')}</div>
                    </div>
                    {defaultFields.map((list , key) => {
                      return (
                        list.field === 'country' ?
                        <div className="row dropdown-row" key={key}>
                          <div className="text">{list.label}
                            {list.isRequred === true ?
                              ''
                              :
                              <span className="required-text"> {t('earningsPage.optionalFieldText')}</span>

                            }
                          </div>
                            <Select
                              classNamePrefix="react-select"
                              isMulti={false}
                              isSearchable ={false}
                              value={[countryData.find(opt =>opt.value === this.state.user[list.field])]}
                              name={list.field}
                              options={countryData}
                              onChange={this.logChange}
                              className={this.state.errorClasses[list.field] + ' react-select'}
                              placeholder =''
                            />
                          <div className="invalid-feedback">{this.state.errors[list.field]}</div>
                        </div>
                        :
                          
                        <div className={list.type === 'dropdown' ? 'dropdown-row row' : 'row'} key={key}>
                          <div className="text">{list.label}
                            {list.isRequred === true ?
                              ''
                              :
                              <span className="required-text"> {t('earningsPage.optionalFieldText')}</span>

                            }
                          </div>
                          
                          { list.type === 'dropdown' ?
                            <Select
                              classNamePrefix="react-select"
                              isMulti={false}
                              isSearchable ={false}
                              value={[list.options.find(opt =>opt.value === Number(this.state.user[list.field]))]}
                              name={list.field}
                              options={list.options}
                              onChange={(newValue, actionMeta)=>this.dropdownChange(newValue, actionMeta)}
                              className={this.state.errorClasses[list.field] + ' react-select'}
                              placeholder =''
                              />
                          :   
                              list.type === 'date' ?
                                <DatePicker
                                  customInput={<ExampleCustomInput errorClass={this.state.errorClasses[list.field]} />}
                                  selected={this.state.user[list.field] === '' ? '' :  new Date([this.state.user[list.field]]) }
                                  onChange={this.dateChange.bind(this, list.field)}
                                  name={list.field}
                                  showYearDropdown
                                  showMonthDropdown
                                  dropdownMode="select"
                                  className={this.state.errorClasses[list.field]}
                                  dateFormat="dd/MM/yyyy"
                                />
                              :
                                <input onChange={this.handleChange} className={this.state.errorClasses[list.field]} type={list.type} name={list.field} value={this.state.user[list.field]} />


                          }
                          <div className="invalid-feedback">{this.state.errors[list.field]}</div>
                        </div>
                      )
                    })}
                    {additionalFields !== undefined &&  additionalFields.length !== 0 ?
                      additionalFields.map((list , key) => {
                        return (
                          <div className={list.type === 'dropdown' ? 'dropdown-row row' : 'row'} key={key}>
                            <div className="text">{list.label}
                              {list.isRequred === true ?
                                ''
                                :
                                <span className="required-text"> {t('earningsPage.optionalFieldText')}</span>

                              }
                            </div>
                            { list.type === 'dropdown' ?
                              <Select
                                classNamePrefix="react-select"
                                isMulti={false}
                                isSearchable ={false}
                                value={[list.options.find(opt =>opt.value === Number(this.state.user[list.field]))]}
                                name={list.field}
                                options={list.options}
                                onChange={(newValue, actionMeta)=>this.dropdownChange(newValue, actionMeta)}
                                className={this.state.errorClasses[list.field] + ' react-select'}
                                placeholder =''
                                />
                            :
                            list.type === 'date' ?
                              <DatePicker
                                customInput={<ExampleCustomInput errorClass={this.state.errorClasses[list.field]} />}
                                selected={this.state.user[list.field] === '' ? '' : new Date([this.state.user[list.field]]) }
                                onChange={this.dateChange.bind(this, list.field)}
                                name={list.field}
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                className={this.state.errorClasses[list.field]}
                                dateFormat="dd/MM/yyyy"
                              />
                            :
                              <input onChange={this.handleChange} className={this.state.errorClasses[list.field]} type={list.type} name={list.field} value={this.state.user[list.field]} />

                            }
                            <div className="invalid-feedback">{this.state.errors[list.field]}</div>
                          </div>
                        )
                      })
                      :
                      ''
                    }
                  </div>
                </div>
                <div className="right-section">
                  <div className="section-box">
                    <div className="title">{t('earningsPage.availableBalanceText')}</div>
                    <div className="price">{parseInt((userBalance.balance * 100) / 100 , 0) + ' ֏'}</div>
                    <div className="row input-row">
                      <div className="text">{t('earningsPage.withDrawFieldTitle')}</div>
                      <div className="wrap">
                        <input id="yourinput" name="whitDrawAmount" className={this.state.amountFieldErrorClass}  type="text" onChange={this.handleWithDraw} placeholder=""/>
                        <span className="sign">֏</span>
                        <span className={this.state.amountFieldErrorClass + ' min-amount'}>{t('earningsPage.minimalText') + ' '+ userBalance.minimumAmount+ ' ֏'}</span>
                      </div>
                    </div>
                    <div className="info-box">
                      <div className="tax item clear">
                        <div className="text">{t('earningsPage.taxText')}</div>
                        <div className="price">{this.state.feePercentAmount === 0 ? this.state.feePercentAmount+ ' ֏' : '-'+this.state.feePercentAmount+ ' ֏'}</div>
                      </div>
                      <div className="fee item clear">
                        <div className="text">{t('earningsPage.feeText')}</div>
                        <div className="price">{this.state.transferFeeAmount === 0 ? this.state.transferFeeAmount+ ' ֏' : '-'+this.state.transferFeeAmount+ ' ֏'}</div>
                      </div>
                      <div className="total">
                        <div className="title">{t('earningsPage.finelWithDrawText')}</div>
                        <div className="price">{this.state.totalWithdrawAmount+ ' ֏'}</div>
                      </div>
                    </div>
                    {userBalance.balance !== undefined && userBalance.balance > 0 ?
                      <div onClick={this.withdraw} className="button">{t('earningsPage.withDrawButtonText')}</div>
                    :
                      <div className="button-disabled">{t('earningsPage.withDrawButtonText')}</div>
                    }
                    {this.state.messageFromServer ?
                      <div className="error-text">
                        {this.state.messageFromServer}
                      </div>
                      :
                      ''
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <Footer/>
      </div>
    );
  }
}

WithDraw.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(WithDraw);
