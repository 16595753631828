import React from 'react';
import { Route, Redirect } from 'react-router-dom';
let languageCode
if(localStorage.getItem('lang')) {
  let language = JSON.parse(localStorage.getItem('lang'))

  languageCode = language.code
}else {
  
  languageCode = 'hy'
}
 
export const PrivateRoute = ({ component: Component ,  ...rest }) => {
  return <Route {...rest} render={props => (
      localStorage.getItem('user')
          ? <Component {...props} />
        : <Redirect to={{ pathname: `/${languageCode}/sign-in`, state: { from: props.location } }} />
  )} />
}
