import React, { Component } from 'react';
// import Header from '../headerComponent/header'
// import Footer from '../footerComponent/footer'
import {
  Link
} from 'react-router-dom';
import MetaTags from '../metaTags'

import {
  isMobile
} from "react-device-detect";
import Logo from '../../Assets/images/svg/footer-logo.svg';

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
class Notfound extends Component {
  componentDidMount(){
    document.body.classList.add('error-page');
  }
  componentWillUnmount(){
    document.body.classList.remove('error-page');
  }
  render() {
    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem('lang'))
    let languageCode = language.code
    return (
      <div className="error-page-container imgloaded">
        <MetaTags/>
        <div className="logo">
          <Link to={`/${language.code}/`}>
            <img src={Logo} alt="" />
          </Link>
        </div>
        { isMobile ?
          <div className="glitch">
            <div className="glitch__bg"></div>
          </div>
          :
          <div className="glitch">
  					<div className="glitch__img"></div>
  					<div className="glitch__img"></div>
  					<div className="glitch__img"></div>
  					<div className="glitch__img"></div>
  					<div className="glitch__img"></div>
  				</div>

        }
        <div className="table">
          <div className="cell">
            <div className="wrap">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.129 218.477">
                <path id="Path_1020" data-name="Path 1020" className="cls-1" d="M-164.541,0V-57.641h-87.523V-78.359l87.523-127.766h24.836V-78.359h38.117v20.719h-38.117V0Zm0-78.359v-90.312l-61.227,90.313ZM-.066-212.234q35.2,0,53.656,29.551t18.461,79.621q0,50.2-18.461,79.754T-.066,6.242q-35.062,0-53.59-29.551t-18.527-79.754q0-50.07,18.527-79.621T-.066-212.234ZM-40.309-58.969l71.32-111.7q-6.773-12.352-14.676-16.668a33.779,33.779,0,0,0-16.4-4.316q-22.18,0-33.734,24.637t-11.555,62.488q0,8.633.531,18.594T-40.309-58.969ZM39.91-146.094-30.879-33.734q5.711,9.7,13.148,14.543a29.752,29.752,0,0,0,16.6,4.848q25.234,0,35.859-28.223t10.625-62.621A152.519,152.519,0,0,0,39.91-146.094ZM189.112,0V-57.641H101.588V-78.359l87.523-127.766h24.836V-78.359h38.117v20.719H213.948V0Zm0-78.359v-90.312L127.885-78.359Z" transform="translate(252.064 212.234)"/>
              </svg>
              <div className="description">
                {t('notFound.pageTitle')}
              </div>
              <Link className="button" to={`/${languageCode}/`}>{t('notFound.buttonText')}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Notfound.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Notfound);
