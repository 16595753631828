import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "./_helpers";
import { alertActions } from "./_actions";
import { PrivateRoute } from "./_components";
import "./Assets/css/style.css";

import Registration from "./components/pages/registrationPage";
import LoginPage from "./components/pages/loginPage";
import Notfound from "./components/pages/notFound";
import Homepage from "./components/pages/homePage";
import UserPage from "./components/pages/userPage";
import ReturnPolicyPage from "./components/pages/returnPolicy";
import LegalPage from "./components/pages/legalPage";
import PrivacyPolicy from "./components/pages/privacyPolicy";
import ContactPage from "./components/pages/contact";
import AboutPage from "./components/pages/about";
//import FaqPage from './components/pages/faq'
import EarningsPage from "./components/pages/earnings";
import ForgotPassword from "./components/pages/forgotPassword";
import ResetPassword from "./components/pages/resetPassword";
import VerifyAccount from "./components/pages/verifyAccount";
import AdsAndSponsored from "./components/pages/adsAndSponsored";
import Category from "./components/category/category";
import Search from "./components/post/search";
import PostSingle from "./components/post/postInner";

import Livepage from "./components/live/livePage";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import Header from "./components/headerComponent/header";
import { DefaultLayout } from "./containers";

import ReactGA from "react-ga";

import ReactPixel from "react-facebook-pixel";

import CacheBuster from "./CacheBuster";

// Translation Higher Order Component
import { setLanguage } from "react-switch-lang";

// const advancedMatching = { em: 'some@email.com' };
const options = {
  autoConfig: true,
  debug: false
};
ReactPixel.init("854899708226842", options);

ReactPixel.pageView();
// ReactPixel.track( event, data )
// ReactPixel.trackCustom( event, data )

class BaseApp extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    this.state = {
      value: "",
      metaInfo: {},
      ready: false,
      date: new Date().getTime()
    };
    this._isLoaded = false;
  }
  componentDidMount() {
    let currentComponent = this;
    currentComponent._isLoaded = true;

    if (this.props["dispatch"].length === 1) {
      if (currentComponent._isLoaded) {
        setTimeout(function() {
          currentComponent.setState({ ready: true });
        }, 300);
      }
    }
    // console.log( window.location.pathname.substring(1))

    let windowUrl = window.location.pathname.split("/");
    // let windowUrl = window.location.pathname.split('/')
    if (windowUrl[1] === "en" || windowUrl[1] === "hy") {
      const labgInfo = {
        code: windowUrl[1]
      };
      setLanguage(windowUrl[1]);
      localStorage.setItem("lang", JSON.stringify(labgInfo));
    }
    // console.log(windowUrl);
    let urlParams = "";
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    if (
      (window.location.search !== "") &
      (window.location.search !== undefined)
    ) {
      urlParams = window.location.search;
    }

    if (localStorage.getItem("lang")) {
      if (windowUrl[1] !== "en" && windowUrl[1] !== "hy") {
        setLanguage(languageCode);
        let url =
          "/" +
          languageCode +
          "/" +
          window.location.pathname.substring(1) +
          urlParams;
        history.push(url);
      } else {
        if (windowUrl.length === 2) {
          let url = "/" + languageCode + "/";
          history.push(url);
        }
      }
    } else {
      let url = "/hy/" + window.location.pathname.substring(1) + urlParams;
      history.push(url);
    }
  }
  componentWillUnmount() {
    this._isLoaded = false;
  }

  callbackFunction = childData => {
    if (childData === true) {
      this.setState({
        date: new Date().getTime()
      });
    }
  };
  render() {
    // console.log(React.version)
    if (window.location.pathname !== "/user-dashbord/advertisement/add") {
      function hasTouch() {
        return (
          "ontouchstart" in document.documentElement ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        );
      }

      if (hasTouch()) {
        // remove all :hover stylesheets
        try {
          // prevent exception on browsers not supporting DOM styleSheets properly
          for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
              if (!styleSheet.rules[ri].selectorText) continue;

              if (styleSheet.rules[ri].selectorText.match(":hover")) {
                styleSheet.deleteRule(ri);
              }
            }
          }
        } catch (ex) {}
      }
    }

    // console.log(this.state)

    if (this._isLoaded === false) {
      return false;
    }
    ReactGA.initialize("UA-142780835-1");
    // console.log(window.location.search)
    // console.log(window.location.pathname)
    ReactGA.pageview(window.location.pathname + window.location.search);

    //var is_root = (window.location.pathname === "/" || window.location.pathname === "/live");
    var is_root =
      window.location.pathname === "/hy/" ||
      window.location.pathname === "/en/" ||
      window.location.pathname === "/hy/live" ||
      window.location.pathname === "/en/live";

    if (is_root) {
      document.body.classList.add("dark-bg");
    } else {
      document.body.classList.remove("dark-bg");
    }

    if (window.location.pathname !== "/search") {
      var input = document.getElementById("search-input");
      if (input) {
        if (input.value !== undefined) {
          input.value = "";
        }
      }
    }
    const awesomePlaceholder = (
      <div className="loader-box">
        <span className="loader">
          <span className="loader-inner"></span>
        </span>
      </div>
    );
    let lang = "en|hy";
    return (
      <div>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }

            return "";
          }}
        </CacheBuster>
        <Router history={history}>
          <div>
            <ReactPlaceholder
              ready={this.state.ready}
              customPlaceholder={awesomePlaceholder}
            >
              <Header
                parentCallback={this.callbackFunction}
                key={this.state.date}
              />
              <Switch>
                <Route
                  exact
                  path={`/:lang(${lang})/sign-in`}
                  component={LoginPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/sign-up`}
                  component={Registration}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/`}
                  component={Homepage}
                  key={new Date().getTime()}
                />
                <Route
                  exect
                  path={`/:lang(${lang})/not-found`}
                  component={Notfound}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/category/:id`}
                  component={Category}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/return-policy`}
                  component={ReturnPolicyPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/legal`}
                  component={LegalPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/privacy-policy`}
                  component={PrivacyPolicy}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/post/:id`}
                  component={PostSingle}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/search`}
                  component={Search}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/user/:id`}
                  component={UserPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/live`}
                  component={Livepage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/contact-us`}
                  component={ContactPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/about-us`}
                  component={AboutPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/earning-calculations`}
                  component={EarningsPage}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/forgot-password`}
                  component={ForgotPassword}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/reset-password`}
                  component={ResetPassword}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/verify-account`}
                  component={VerifyAccount}
                  key={new Date().getTime()}
                />
                <Route
                  exact
                  path={`/:lang(${lang})/ads-and-sponsored`}
                  component={AdsAndSponsored}
                  key={new Date().getTime()}
                />
                <PrivateRoute exact component={DefaultLayout} />
              </Switch>
            </ReactPlaceholder>
          </div>
        </Router>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}
const App = connect(mapStateToProps)(BaseApp);

export default App;
