import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import registerServiceWorker   from './registerServiceWorker';
// import unregister   from './registerServiceWorker';
import initReactFastclick from 'react-fastclick';

import {
  setDefaultLanguage,setTranslations
} from 'react-switch-lang';
import en from './components/translates/en.json';
import hy from './components/translates/hy.json';
if(!localStorage.getItem('lang')){
  var maintenanceText = "";
  localStorage.setItem('lang', JSON.stringify({code:'hy'}));
  setDefaultLanguage('hy');
  document.documentElement.lang = 'hy'
  maintenanceText = "Ինչ-որ բան սխալ է։ Խնդրում ենք փորձել մի փոքր ուշ։"
}else {
  var lang = JSON.parse(localStorage.getItem('lang'))
  setDefaultLanguage(lang.code);
  document.documentElement.lang = lang.code
  var maintenanceText = "";
  if(lang.code === "hy") {
    maintenanceText = "Ինչ-որ բան սխալ է։ Խնդրում ենք փորձել մի փոքր ուշ։"
  }
  if(lang.code === "en") {
    maintenanceText = "Something is wrong. Please try again later."
  }
}
setTranslations({ en, hy });
initReactFastclick();




 
// Do this two lines only when setting up the application

const isReachable = require('is-reachable');
const url = process.env.REACT_APP_API_DOMAIN;

(async () => {
  if(await isReachable(url)) {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('iammedia')
    );
    registerServiceWorker();
    //unregister()
  }else {
    ReactDOM.render(
        <Provider store={store}>
          <div className="global-error">{maintenanceText}</div>
        </Provider>,
        document.getElementById('iammedia')
    );
    registerServiceWorker();
    //unregister()

  }
})();
