import React, { Component } from 'react';
import axios from 'axios'
import { authHeader } from '../../../_helpers';
import {
  Redirect,Link
} from 'react-router-dom';
import MetaTags from '../../metaTags'
import Footer from '../../footerComponent/footer'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
var querystring = require('querystring');

const apiUrl = process.env.REACT_APP_API
//var querystring = require('querystring');
class PurchaseInfo extends Component {
  constructor(props) {
    super(props);
    //const type=props.type;


    this.state = {
      paymentInfo:{},
      redirect:false,
      redirectUrl:'',
      messageFromServer:'',
      loaderScreen:false,
      paymentId:''
    }

    this.payRequest = this.payRequest.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this);

  }
  _isloaded = false;
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidMount(){
    let currentComponent = this;
    currentComponent._isMounted = true
    let curentUser = JSON.parse(localStorage.getItem('user'));

    axios.post(apiUrl+'/payments/verify/'+currentComponent.props.match.params.id,{user:curentUser._id},{
      headers:authHeader()
    }).then(function(response) {
      console.log(response)
      if(response.data.code === 200 ){
        if(currentComponent._isMounted) {
          currentComponent.setState({
            paymentInfo:response.data
          })
        }
      }else if(response.data.code === 404) {
        if(currentComponent._isMounted) {
          let langCode = JSON.parse(localStorage.getItem('lang'))
          langCode = langCode.code
          currentComponent.setState({
              redirect:true,
              redirectUrl:'/'+langCode+'/not-found'
          });
        }
      }else if(response.data.code === 401) {
        if(currentComponent._isMounted) {
          if(response.data.payment !== undefined && response.data.payment !== '') {
            currentComponent.setState({
              messageFromServer:response.data.message,
              paymentId:response.data.payment
            })
          }else {
            currentComponent.setState({
              messageFromServer:response.data.message
            })
          }
        }
      }
    });
  }
  handlePurchase(e){
    var id = e.target.id
    let postdata= {
      payment:id
    }

    axios.post(apiUrl+'/payment/resend/',querystring.stringify(postdata),{
      headers:authHeader()
    }).then(function(response) {
      if(response.data.code === 200 ){
        if(response.data.success.url !== undefined || response.data.success.url !== '') {
          window.location = response.data.success.url
        }
      }
    });
  }
  payRequest(){
    //let currentComponent = this

    axios.post(apiUrl+'/payment/submit/'+this.state.paymentInfo.payment.id,'',
       {
         headers:authHeader()
        }).then(function(response) {
          var data = response.data
          if(data.code === 200){
            let url = data.success.bankUrl;
            window.location = url
          }
          else {
            // let url = '/verify-email';
            // currentComponent.setState({redirectUrl:url});
            // currentComponent.setState({redirect:true});
          }
    });
    this.setState({
      loaderScreen:true,
    })
  }
  render() {
    if(this.state.redirect){
       return (<Redirect to={this.state.redirectUrl} />);
     }
     if(this._isMounted) {
       var data = this.state.paymentInfo;

     }else {
       return false
     }
    const { t } = this.props;
    let langCode = JSON.parse(localStorage.getItem('lang'))
    langCode = langCode.code
    return (
      <div>
        <div className="purchase-info-container">
          <MetaTags/>
          {this.state.messageFromServer !== '' ?
            <div>
              <div className="message-from-server">{this.state.messageFromServer}</div>
              {this.state.paymentId !== '' ?
                <div className="resend-button" onClick={this.handlePurchase} id={this.state.paymentId}>{t('purchasePage.payButtonText')}</div>
                :
                <Link className="button" to={`/${langCode}/`}>{t('purchasePage.homeButtonText')}</Link>
              }
            </div>
            :
            ''
          }
          {this.state.loaderScreen ?
            <div className="loader-box">
              <span className="loader"><span className="loader-inner"></span></span>
            </div>
            :
            ''
          }

          {data !== '' && data.payment && data !== undefined ?
            <div className="info">
              <div className="section-title">{t('purchasePage.successTitle')}</div>
              <div className="box">
                <div className="type-title">{data.object.typeName}</div>
                <div className="title">{data.object.title}</div>
                <div className="range">{data.object.startDate+ '-' + data.object.endDate}</div>
                <div className="price">{data.payment.amount+' ֏'}</div>
                <div className="button" onClick={this.payRequest}>{t('purchasePage.payButtonText')}</div>
              </div>
            </div>
            :
            ''
          }
        </div>
        <Footer/>
      </div>
    );
  }
}

PurchaseInfo.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(PurchaseInfo);
