import React, { Component } from "react";
import FooterLogo from "../../Assets/images/svg/footer-logo.svg";
import AppStoreLogo from "../../Assets/images/svg/appStore-icon.svg";
import GooglePlayLogo from "../../Assets/images/svg/googlePlay-icon.svg";

import axios from "axios";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";
const apiUrl = process.env.REACT_APP_API;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerMenu: []
    };
  }
  _isMounted = false;

  componentDidMount() {
    let currentComponent = this;
    currentComponent._isMounted = true;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    axios
      .get(apiUrl + "/footer-links/all-by-columns", {
        headers: { lang: languageCode }
      })
      .then(function(response) {
        const footerItems = response.data;
        if (currentComponent._isMounted) {
          currentComponent.setState({ footerMenu: footerItems });
        }
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    return (
      <footer>
        <div className="footer-wrapper">
          <div className="top clear">
            <div className="col-1 item">
              <div className="logo">
                <Link to={`/${languageCode}/`}>
                  <img src={FooterLogo} alt="" />
                </Link>
              </div>
              <div className="text">{t("footer.footerDescription")}</div>
              <div className="app-icons">
                <a
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/ar/app/iammedia-online-media/id1459617450?l=en"
                  target="_blank"
                >
                  <img src={AppStoreLogo} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.digitec.iammedia"
                  target="_blank"
                >
                  <img src={GooglePlayLogo} alt="" />
                </a>
              </div>
            </div>
            {Object.keys(this.state.footerMenu).map((list, key) => {
              var count = parseInt(list, 10) + 1;
              return (
                <div className={"col-" + count + " item menu"} key={list}>
                  {this.state.footerMenu[list].map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/${languageCode}${item.url}`}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="bottom clear">
            <div className="social">
              <div className="icons">
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/iammedia.am/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 73.121 73.121"
                  >
                    <g
                      id="Group_362"
                      data-name="Group 362"
                      transform="translate(60 -1298)"
                    >
                      <rect
                        id="Rectangle_1042"
                        data-name="Rectangle 1042"
                        className="cls-1"
                        width="73.121"
                        height="73.121"
                        transform="translate(-60 1298)"
                      />
                      <path
                        id="Path_1047"
                        data-name="Path 1047"
                        className="cls-2"
                        d="M266.29,35.239h4.56V28.4H264.4c-3.742,0-8.6,2.409-8.6,9.377V43.2H249.6v7.054h6.194v18.41h7.4V50.252h6.151l1.032-7.054h-7.183V38.681C263.193,36.358,264.311,35.239,266.29,35.239Z"
                        transform="translate(-283.6 1285.6)"
                      />
                    </g>
                  </svg>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/iammedia.am/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 73.121 73.121"
                  >
                    <g
                      id="Group_361"
                      data-name="Group 361"
                      transform="translate(-33.84 -1298)"
                    >
                      <rect
                        id="Rectangle_1043"
                        data-name="Rectangle 1043"
                        className="cls-1"
                        width="73.121"
                        height="73.121"
                        transform="translate(33.84 1298)"
                      />
                      <g
                        id="Group_359"
                        data-name="Group 359"
                        transform="translate(49.306 1313)"
                      >
                        <g
                          id="Group_358"
                          data-name="Group 358"
                          transform="translate(0)"
                        >
                          <g id="Group_357" data-name="Group 357">
                            <path
                              id="Path_1050"
                              data-name="Path 1050"
                              className="cls-2"
                              d="M65.23,254.3a10.929,10.929,0,1,0,10.93,10.929A10.951,10.951,0,0,0,65.23,254.3Zm0,18a7.075,7.075,0,1,1,7.075-7.075A7.076,7.076,0,0,1,65.23,272.3Z"
                              transform="translate(-44.11 -244.11)"
                            />
                            <path
                              id="Path_1051"
                              data-name="Path 1051"
                              className="cls-2"
                              d="M94.034,248.9a2.534,2.534,0,1,0,2.534,2.534A2.535,2.535,0,0,0,94.034,248.9Z"
                              transform="translate(-61.668 -241.561)"
                            />
                            <path
                              id="Path_1052"
                              data-name="Path 1052"
                              className="cls-2"
                              d="M65.2,235H47.038A12.033,12.033,0,0,0,35,247.038v18.11a12.033,12.033,0,0,0,12.038,12.038h18.11a12.067,12.067,0,0,0,12.038-12.038v-18.11A11.988,11.988,0,0,0,65.2,235Zm8.184,30.2a8.191,8.191,0,0,1-8.184,8.184H47.038a8.191,8.191,0,0,1-8.184-8.184v-18.11a8.191,8.191,0,0,1,8.184-8.184h18.11a8.191,8.191,0,0,1,8.184,8.184V265.2Z"
                              transform="translate(-35 -235)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UC7yMZXtHUPjjmU0rJ4Soc8w"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 73.121 73.121"
                  >
                    <g
                      id="Group_360"
                      data-name="Group 360"
                      transform="translate(-133 -1298)"
                    >
                      <rect
                        id="Rectangle_1044"
                        data-name="Rectangle 1044"
                        className="cls-1"
                        width="73.121"
                        height="73.121"
                        transform="translate(133 1298)"
                      />
                      <g
                        id="Group_355"
                        data-name="Group 355"
                        transform="translate(144 1316)"
                      >
                        <path
                          id="Path_1048"
                          data-name="Path 1048"
                          className="cls-2"
                          d="M2484.367,453.07c-.442-3.656-1.324-4.727-1.955-5.232-1.01-.756-2.836-1.072-5.231-1.2-3.907-.252-10.463-.441-17.585-.441s-13.679.126-17.587.441c-2.4.189-4.223.441-5.231,1.2-.631.5-1.515,1.576-1.955,5.232a136.271,136.271,0,0,0,0,22.692c.44,3.656,1.324,4.727,1.955,5.232,1.009.756,2.835,1.072,5.231,1.2,3.908.252,10.463.441,17.587.441s13.678-.126,17.585-.441c2.4-.189,4.222-.441,5.231-1.2.631-.5,1.513-1.576,1.955 5.232A136.9,136.9,0,0,0,2484.367,453.07Zm-30.824,19.288V456.474l15.192,7.942Z"
                          transform="translate(-2434.349 -446.2)"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/iammedia-am"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="73.121"
                    height="73.121"
                    viewBox="0 0 73.121 73.121"
                  >
                    <g
                      id="Group_391"
                      data-name="Group 391"
                      transform="translate(-9048.5 -7700.5)"
                    >
                      <rect
                        id="Rectangle_1042"
                        data-name="Rectangle 1042"
                        width="73.121"
                        height="73.121"
                        transform="translate(9048.5 7700.5)"
                        fill="none"
                      />
                      <path
                        id="linkedin-square-rounded-icon"
                        d="M1121.949,2097.469h-7.728v-24.964h7.728Zm-3.9-28.234a4.606,4.606,0,1,1,4.569-4.606A4.588,4.588,0,0,1,1118.048,2069.236Zm32.858,28.234h-7.69v-13.105c0-3.593-1.366-5.6-4.208-5.6-3.093,0-4.708,2.089-4.708,5.6v13.105h-7.412v-24.964h7.412v3.362a8.7,8.7,0,0,1,7.522-4.125c5.293,0,9.083,3.234,9.083,9.92Z"
                        transform="translate(7952.522 5657.977)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div className="copy-right">
              IamMedia © {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(Footer);
